import React from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

const Articles = () => {
  const data = useStaticQuery(graphql`
    query AllNews {
      allStrapiArticle(sort: { order: DESC, fields: publishedAt }) {
        nodes {
          title
          slug
          strapi_id
          publishedAt
          img {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);


  return (
    <Layout>
      <div className="container mt-60 mobile-news">
        <h1 className="mb-30 text-center">Новости DENDOR</h1>
        <div className="news_flex">
          {data.allStrapiArticle.nodes.map((node) => {

           let setImg = null;
           if (node.img !== null) {setImg = getImage(
            node.img.localFile.childImageSharp
          );}
            
            return (
              <div className="mb-30 news" key={node.strapi_id}>
              {node.img === null ?  <StaticImage
                className="news__images"
                alt="Новости компании Dendor"
                src="../images/placeholder.png"
                placeholder="#fff"
              /> : <GatsbyImage
              className="news__images"
              placeholder="#fff"
              image={setImg}
              alt={node.title}
              width={360}
              hieght={225}
              layout ="fullWidth"
              aspectRatio={330/225}
            />}
       
              <Link to={`/${node.slug}`}>{node.title}</Link>
              <p className="date_pub">
                Дата публикации:
                {new Date(node.publishedAt).toLocaleDateString()}
              </p>
            </div>
            )
})}
        </div>
      </div>
    </Layout>
  );
};

export default Articles;
